/* eslint-disable eqeqeq */
import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { config } from "_helpers/config";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { docsActions } from "_actions/docs.actions";
import { usPhone } from "_utils/formatter";
import { toast } from "react-toastify";
import { FormNav } from "./FormNav";
import { navigate, validate } from "./formHandlers";
import checkIfDocsShouldBeLocked from "../../_helpers/lockDocs"
import moment from "moment";
import { preventNegativeValueInDocs } from "_helpers/helpers";


class CandidateInfoPage extends Component {
  constructor(props) {
    super(props);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.state = {
      rehire_doc: false,
      doc: {},
      chkSameAddress: false,
      maddress: false,
      mcity: false,
      mstate: false,
      mzip: false,
      tmp_mailing_address: "",
      tmp_mailing_city: "",
      tmp_mailing_state: "",
      tmp_mailing_zip: "",
      user: {
        mailing_address: {
          address: "",
          city: "",
          state: "",
          zip: "",
        },
        physical_address: {
          address: "",
          city: "",
          state: "",
          zip: "",
        },
        emgcontact1: {},
        emgcontact2: {},
        gender: "Male",
        personal_licence_plate_number_check: 0
      },
      form: {
        date: moment().format("MM/DD/YYYY"),
        dob: "",
        height:"",
        feet: "",
        inches: "",
      },
      errors: {},
      form_edited: false,
    };
    // Handlers
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleAddress = this.handleAddress.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validate = validate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.navigate = navigate.bind(this);
    this.goBack = this.goBack.bind(this);
    // Refs
    this.form = React.createRef();
  }

  handleDateChange(field, date) {
    let nextState = this.state;
    if (date){
    _.set(nextState, field, date.format("MM/DD/YYYY"));
    }
    this.setState({
      ...nextState,
      errors: {
        [field]: "",
      },
    });
  }

  handleFormChange(e) {
    // safety check for datepicker
    if (e.target && e.target.attributes["field"]) {
      let field = e.target.attributes["field"].value;
      let nextState = this.state;
      let { value, checked, type } = e.target;
      if (type == "checkbox") {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }
      this.setState({
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: "",
        },
      });
    }
    !this.state.form_edited && this.setState({ form_edited: true });
  }

  handlePhoneChange(e) {
    e.target.value = usPhone(e.target.value);
  }

  handleChange(e) {
    const { name, value } = e.target;
    if (this.state.chkSameAddress) {
      if (name == 'physicalAddress') {
        this.state.user.mailing_address.address = value;
      } else if (name == 'physicalCity') {
        this.state.user.mailing_address.city = value;
      } else if (name == 'physicalState') {
        this.state.user.mailing_address.state = value;
      } else if (name == 'physicalZip') {
        this.state.user.mailing_address.zip = value;
      }
      e.target.setAttribute("errors", "");
    }
    
  }

  handleAddress(e) {
    var name = e.target.name,
      value = e.target.value;

    if (e.target.checked) {
      this.state.user.tmp_mailing_address = this.state.user.mailing_address.address;
      this.state.user.tmp_mailing_city = this.state.user.mailing_address.city;
      this.state.user.tmp_mailing_state = this.state.user.mailing_address.state;
      this.state.user.tmp_mailing_zip = this.state.user.mailing_address.zip;

      this.state.user.mailing_address.address = this.state.user.physical_address.address;
      this.state.user.mailing_address.city = this.state.user.physical_address.city;
      this.state.user.mailing_address.state = this.state.user.physical_address.state;
      this.state.user.mailing_address.zip = this.state.user.physical_address.zip;
      this.state.maddress = true;
      this.state.mcity = true;
      this.state.mstate = true;
      this.state.mzip = true;
      this.state.chkSameAddress = true;

      if(document.getElementById("address"))
      document.getElementById("address").setAttribute('errors', "");

      if(document.getElementById("city"))
      document.getElementById("city").setAttribute('errors', "");

      if(document.getElementById("state"))
      document.getElementById("state").setAttribute('errors', "");

      if(document.getElementById("zip"))
      document.getElementById("zip").setAttribute('errors', "");
    } else {
      this.state.user.mailing_address.address = this.state.user.tmp_mailing_address;
      this.state.user.mailing_address.city = this.state.user.tmp_mailing_city;
      this.state.user.mailing_address.state = this.state.user.tmp_mailing_state;
      this.state.user.mailing_address.zip = this.state.user.tmp_mailing_zip;
      this.state.chkSameAddress = false;
      this.state.maddress = false;
      this.state.mcity = false;
      this.state.mstate = false;
      this.state.mzip = false;
    }
  }

  submitForm(e) {
    e.preventDefault();

    let err = this.validate();
    if (err) {
      return;
    }

    let { doc, user, form } = this.state;

    let _user={...user}

    _user.personal_licence_plate_number_check = Number(_user?.personal_licence_plate_number_check ?? 0)

    if (_user?.personal_licence_plate_number_check) {
      _user.personal_licence_plate_number_check = 1
    } else {
      _user.personal_licence_plate_number_check = 0
      delete _user.plpn_make_or_model
      delete _user.personal_licence_plate_number
    }
    let feet = parseInt(form.feet);
    let inches = parseInt(form.inches);

    let totalHeightInches = feet * 12 + inches;

    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      form
      : {
        ...form,
        height: totalHeightInches.toString(),
      },
      user: _user,
    };

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.props.single_doc ? (data.single_doc = true) : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);

    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (this.props.mobileMode && !this.props.lastPage) {
          this.props.setScreen("sidebar");
          this.navigate("next", this.props.rehire);
        } else if (this.props.lastPage) {
          if (this.props.adminMode) {
            this.props.history.push(`/docs`);
          } else {
            // debugger
            // this.props.history.push(`/dashboard`);
            this.props.goBack()
          }
        } else {
          this.navigate("next", this.props.rehire);
        }
      } else {
        if (this.props.mobileMode) this.props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  }

  // GET FORM data from api with id passed in props
  getFormData(id) {
    this.props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        const currentState = this.state;
        let { user, form } = res.data;

        // date needs to a moment object or Datepicker breaks.
        form.date = form.date || currentState.form.date;
        form.dob = form.dob || currentState.form.dob;
        form.ssn=form.ssn || currentState.form.ssn;
        // spread the data coming from API into state

        //updating data in user object to form object
        form.home_phone=user.phone||currentState.form.home_phone;
        form.cell_phone=user.second_phone||currentState.form.cell_phone;

        /**
         * The user.qualification_id from res can be off different formats and buggy
         */
        if (user.qualification_id.at(0) === ",") {
          const qIDFormatted = user.qualification_id.slice(1);
          this.setState({
            user: { ...user, qualification_id: qIDFormatted },
            form,
          });
          return;
        }else if(user.qualification_id.at(0) === "["){
          const qualificationIdFormatted = JSON.parse(user?.qualification_id)
            ?.map(Number)
            ?.join();
          this.setState({
            user: { ...user, qualification_id: qualificationIdFormatted },
            form,
          });
          return;
        }

        const heightInches = parseInt(form.height);
        const feetForm = parseInt(heightInches / 12);
        const inchesForm = parseInt(heightInches % 12);

        this.setState({
          user,
          form:{
            ...form,
            feet: feetForm,
            inches: inchesForm     
          }
        });
        
      }
    });
  }

  goBack() {
    this.props.resetForm();
    if (this.state.form_edited) {
      this.props.toggleCloseConfirmation(true);
    } else {
      this.props.setScreen("sidebar");
    }
  }

  componentDidMount() {
    let docSlug = this.props.slug ? this.props.slug : this.props.match.params.doc;
    let doc;
    if (this.props.giss) {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 5,
        );
      } else {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id == 4,
        );
      }
    } else {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
        );
      } else {
        this.props.single_doc ? (doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 3,
        )) : (doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id == 1,
        ));
      }
    }
    this.props.single_doc && doc && (doc.id = this.props.new_doc_id);

    this.setState({ doc });
    this.getFormData(doc?.id);
  }

  render() {
    // still linked to state after destructuring
    let { user, form, errors } = this.state;
    // docs_status
    let { adminMode } = this.props;
    let { classifications, pantSize, shirtSize, race, crewsList, userDocs, inseamPantSize } = this.props.docs;
    const shouldLockDocs = checkIfDocsShouldBeLocked(user, form, adminMode)
    return (
  
      <div className='doc_info-rh'>
        {/* Title */}
        <div className='doc-title'>
          <img src={`${config.assetUrl}icons/doc-title-img.svg`} className='doc-title-img' />
          <span className='close-button' onClick={this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} />
          </span>
          <h3>Employee Information Page</h3>
        </div>

        {/* Form */}
        <div className='doc_info-form'>
          <form
            className='info-form'
            ref={this.form}
            onChange={this.handleFormChange}
            onSubmit={this.submitForm}
            name='candidate_info_page'
            noValidate>
            <fieldset disabled={shouldLockDocs}>

            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Full Name<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items group'>
                    <div className='grouped'>
                      <input
                        className={`${errors["user.first_name"] ? "error" : ""}`}
                        type='text'
                        name='first_name'
                        id='first_name'
                        value={user.first_name}
                        field='user.first_name'
                        placeholder='First Name'
                        required
                      />
                      {errors["user.first_name"] && (
                        <label htmlFor='first_name' className='error_label'>
                          {errors["user.first_name"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.middle_name"] ? "error" : ""}`}
                        name='middle_name'
                        id='middle_name'
                        value={user.middle_name}
                        field='user.middle_name'
                        placeholder='Middle Name (Optional)'
                      />
                      {errors["user.middle_name"] && (
                        <label htmlFor='middle_name' className='error_label'>
                          {errors["user.middle_name"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.last_name"] ? "error" : ""}`}
                        name='last_name'
                        id='last_name'
                        value={user.last_name}
                        field='user.last_name'
                        placeholder='Last Name'
                        required
                      />
                      {errors["user.last_name"] && (
                        <label htmlFor='last_name' className='error_label'>
                          {errors["user.last_name"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Preferred Name <span>(Optional)</span>
                  </div>
                  <div className='inputs-items'>
                    <input
                      type='text'
                      className={`${errors["form.prefered_name"] ? "error" : ""}`}
                      name='prefered_name'
                      id='prefered_name'
                      value={form.prefered_name}
                      field='form.prefered_name'
                      placeholder='- Enter here -'
                    />
                    {errors["form.prefered_name"] && (
                      <label htmlFor='prefered_name' className='error_label'>
                        {errors["form.prefered_name"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className='inputs-col'>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Position<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <select
                      className={`${errors["form.position"] ? "error" : ""}`}
                      name='position'
                      id='position'
                      value={form.position}
                      field='form.position'
                      required>
                         <option value=''>- Select -</option>
                     <option value='Crew' selected={form.position === 'Crew'}>Crew</option>
                      <option value='Engine' selected={form.position === 'Engine'}>Engine</option>
                      <option value='Fuels' selected={form.position === 'Fuels'}>Fuels</option>
                      <option value='Admin' selected={form.position === 'Admin'}>Admin</option>
                      <option value='Other' selected={form.position === 'Other'}>Other</option>
                      {/* <option value='tree'>Tree</option> */}
                    </select>
                    {errors["form.position"] && (
                      <label htmlFor='position' className='error_label'>
                        {errors["form.position"]}
                      </label>
                    )}
                  </div>
                </div>
                {/* <div className='inputs-col'>
                  <div className='inputs-head'>
                    Classification<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <select
                      className={`${errors["form.classification"] ? "error" : ""}`}
                      name='classification'
                      id='classification'
                      value={form.classification}
                      field='form.classification'
                      required>
                      <option value=''>- Select -</option>
                      {classifications.map((option) => (
                        <option value={option.id} key={option.id}>
                          {option.value}
                        </option>
                      ))}
                    </select>
                    {errors["form.classification"] && (
                      <label htmlFor='classification' className='error_label'>
                        {errors["form.classification"]}
                      </label>
                    )}
                  </div>
                </div> */}
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>CREW</div>
                  <div className='inputs-items'>
                    <select
                      className={`${errors["user.crew_identifier_id"] ? "error" : ""}`}
                      name='crew'
                      id='crew'
                      value={user.crew_identifier_id}
                      field='user.crew_identifier_id'>
                      <option value=''>- Select -</option>
                      {crewsList.map((crew) => (
                        <option value={crew.id} key={crew.id}>
                          {crew.resource_name}
                        </option>
                      ))}
                    </select>
                    {errors["user.crew_identifier_id"] && (
                      <label htmlFor='crew' className='error_label'>
                        {errors["user.crew_identifier_id"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    SSN<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items '>
                    <input
                      type='number'
                      className={`${errors["form.ssn"] ? "error" : ""}`}
                      name='ssn'
                      id='ssn'
                      value={form.ssn}
                      field='form.ssn'
                      placeholder='- Enter here -'
                      required
                      onKeyDown={preventNegativeValueInDocs}
                    />
                    {errors["form.ssn"] && (
                      <label htmlFor='ssn' className='error_label'>
                        {errors["form.ssn"]}
                      </label>
                    )}
                  </div>
                  <p className='notes'>*Work eligibility will be confirmed via E-Verify System</p>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Driver License or State ID
                    <span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items group two-item'>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.drivers_license"] ? "error" : ""}`}
                        name='drivers_license'
                        id='drivers_license'
                        value={user.drivers_license}
                        field='user.drivers_license'
                        placeholder='- Enter here -'
                        required
                      />
                      {errors["user.drivers_license"] && (
                        <label htmlFor='drivers_license' className='error_label'>
                          {errors["user.drivers_license"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.DLState"] ? "error" : ""}`}
                        name='DLState'
                        id='DLState'
                        value={user.DLState}
                        field='user.DLState'
                        placeholder='State'
                        required
                      />
                      {errors["user.DLState"] && (
                        <label htmlFor='DLState' className='error_label'>
                          {errors["user.DLState"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <DatePicker
                        minDate={moment()}
                        className={`${errors["user.useritemdates.dl_expiry"] ? "error" : ""}`}
                        name='dl_expiry'
                        id='dl_expiry'
                        required={true}
                        field='user.useritemdates.dl_expiry'
                        placeholderText='Expiry date'
                        selected={user?.useritemdates?.dl_expiry && moment(user?.useritemdates?.dl_expiry)?.isValid() ? moment(user?.useritemdates?.dl_expiry) : ""}
                        onChange={this.handleDateChange.bind(this, "user.useritemdates.dl_expiry")}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        autoComplete='off'
                      />
                      {errors["dl_expiry"] && (
                        <label htmlFor='dl_expiry' className='error_label'>
                          {errors["dl_expiry"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div className='row form-row'>
                <div className='col'>
                  <div className='inputs-col'> 
                    <div className='inputs-head'>
                      Personal License Plate Number
                      {user.personal_licence_plate_number_check == 1&&(
                        <span className='required-star'>&#x2A;</span>
                      )}   
                    </div>
                    <div className='check_radio_group gender_radio'>
                      <div className='check_radio_item agreement_radio'>
                        <input
                          type='radio'
                          name='personal_licence_plate_number_check'
                          value={0}
                          field='user.personal_licence_plate_number_check'
                          id='0'
                          checked={user.personal_licence_plate_number_check == 0}   
                        />
                        <span className='radio_checkbox'></span>
                        <label htmlFor='male'>No</label>
                      </div>
                      <div className='check_radio_item agreement_radio'>
                        <input
                          type='radio'
                          name='personal_licence_plate_number_check'
                          value={1}
                          field='user.personal_licence_plate_number_check'
                          id='1'
                          checked={user.personal_licence_plate_number_check == 1}
                          
                        />
                        <span className='radio_checkbox'></span>
                        <label htmlFor='female'>Yes</label>
                      </div>
                    </div>
                    {user.personal_licence_plate_number_check == 1 && (
                      <>
                        <div className='inputs-items group two-item personal_licence_plate_container'>
                          <div className='grouped'>
                            <input
                              type='text'
                              className={`${errors["user.personal_licence_plate_number"] ? "error" : ""}`}
                              name='personal_licence_plate_number'
                              id='personal_licence_plate_number'
                              value={user.personal_licence_plate_number}
                              field='user.personal_licence_plate_number'
                              placeholder='License Number'
                              required={user.personal_licence_plate_number_check == 1}
                            />
                            {errors["user.personal_licence_plate_number"] && (
                              <label htmlFor='personal_licence_plate_number' className='error_label'>
                                {errors["user.personal_licence_plate_number"]}
                              </label>
                            )}
                          </div>
                          <div className='grouped'>
                            <input
                              type='text'
                              className={`${errors["user.plpn_make_or_model"] ? "error" : ""}`}
                              name='plpn_make_or_model'
                              id='plpn_make_or_model'
                              value={user.plpn_make_or_model}
                              field='user.plpn_make_or_model'
                              placeholder='Make / Model'
                              required={user.personal_licence_plate_number_check == 1}
                            />
                            {errors["user.plpn_make_or_model"] && (
                              <label htmlFor='plpn_make_or_model' className='error_label'>
                                {errors["user.plpn_make_or_model"]}
                              </label>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Sex<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='check_radio_group gender_radio'>
                    <div className='check_radio_item agreement_radio'>
                      <input
                        type='radio'
                        name='gender'
                        value='Male'
                        field='user.gender'
                        id='male'
                        checked={user.gender === "Male"}
                        required
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='male'>Male</label>
                    </div>
                    <div className='check_radio_item agreement_radio'>
                      <input
                        type='radio'
                        name='gender'
                        value='Female'
                        field='user.gender'
                        id='female'
                        checked={user.gender === "Female"}
                        required
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='female'>Female</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col half-col dress-measurements'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Height <span className='required-star'>&#x2A;</span>
                    <span>(in ft./in.)</span>
                  </div>
                 <div style={{ display: 'flex'}}>
                  <div className='inputs-items'>
                    <input
                      type='number'
                      className={`${errors["form.feet"] ? "error" : ""}`}
                      name='height'
                      id='height'
                      value={form.feet}
                      field='form.feet'
                      placeholder='-Feet-'
                      required
                    />
                    {errors["form.feet"] && (
                      <label htmlFor='height' className='error_label'>
                        Please provide a valid input
                      </label>
                    )}
                  </div>
                  <div className='inputs-items'>
                    <input
                      type='number'
                      className={`${errors["form.inches"] ? "error" : ""}`}
                      name='height'
                      id='height'
                      value={form.inches}
                      field='form.inches'
                      placeholder='-Inch-'
                      required
                    />
                    {errors["form.inches"] && (
                      <label htmlFor='height' className='error_label'>
                        Please provide a valid input
                      </label>
                    )}
                  </div>
                </div>
                </div>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Weight <span className='required-star'>&#x2A;</span>
                    <span>(in lbs)</span>
                  </div>
                  <div className='inputs-items'>
                    <input
                      type='number'
                      className={`${errors["user.weight"] ? "error" : ""}`}
                      name='weight'
                      id='weight'
                      value={user.weight}
                      field='user.weight'
                      placeholder='- Enter here -'
                      required
                    />
                    {errors["user.weight"] && (
                      <label htmlFor='weight' className='error_label'>
                        {errors["user.weight"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Pant Size<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <select
                      className={`${errors["user.pant_size"] ? "error" : ""}`}
                      name='pant_size'
                      id='pant_size'
                      value={user.pant_size}
                      field='user.pant_size'
                      required>
                      <option value=''>- Select -</option>
                      {pantSize.map((size) => (
                        <option value={size.id} key={size.id}>
                          {size.value}
                        </option>
                      ))}
                    </select>
                    {errors["user.pant_size"] && (
                      <label htmlFor='pant_size' className='error_label'>
                        {errors["user.pant_size"]}
                      </label>
                    )}
                  </div>
                </div>

                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Inseam Pant Size<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <select
                      className={`${errors["user.inseam_pant_size"] ? "error" : ""}`}
                      name='inseam_pant_size'
                      id='inseam_pant_size'
                      value={user.inseam_pant_size}
                      field='user.inseam_pant_size'
                      required>
                      <option value=''>- Select -</option>
                      {inseamPantSize.map((size) => (
                        <option value={size.id} key={size.id}>
                          {size.value}
                        </option>
                      ))}
                    </select>
                    {errors["user.inseam_pant_size"] && (
                      <label htmlFor='inseam_pant_size' className='error_label'>
                        {errors["user.inseam_pant_size"]}
                      </label>
                    )}
                  </div>
                </div>
                
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Shirt Size<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <select
                      className={`${errors["user.shirt_size"] ? "error" : ""}`}
                      name='shirt_size'
                      id='shirt_size'
                      value={user.shirt_size}
                      field='user.shirt_size'
                      required>
                      <option value=''>- Select -</option>
                      {shirtSize.map((size) => (
                        <option value={size.id} key={size.id}>
                          {size.value}
                        </option>
                      ))}
                    </select>
                    {errors["user.shirt_size"] && (
                      <label htmlFor='shirt_size' className='error_label'>
                        {errors["user.shirt_size"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    DOB<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <DatePicker
                      className={`${errors["form.dob"] ? "error" : ""} w-100`}
                      name='dob'
                      id='dob'
                      required
                      selected={form.dob ? moment(form.dob) : ""}
                      field='form.dob'
                      placeholder='mm/dd/yyyy'
                      onChange={this.handleDateChange.bind(this, "form.dob")}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      autoComplete='off'
                    />
                    {errors["dob"] && (
                      <label htmlFor='dob' className='error_label'>
                        {errors["dob"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Race or Ethnicity
                    <span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <select
                      className={`${errors["form.ethnicity"] ? "error" : ""}`}
                      name='ethnicity'
                      id='ethnicity'
                      value={form.ethnicity}
                      field='form.ethnicity'
                      required>
                      <option value=''>- Select -</option>
                      {race.map((option) => (
                        <option value={option.id} key={option.id}>
                          {option.value}
                        </option>
                      ))}
                    </select>
                    {errors["form.ethnicity"] && (
                      <label htmlFor='ethnicity' className='error_label'>
                        {errors["form.ethnicity"]}
                      </label>
                    )}
                  </div>
                  <p className='notes'>*Required for Affirmative Action Program</p>
                </div>
              </div>
            </div>

            {/* Address */}
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Physical Address<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <input
                      type='text'
                      className={`${errors["user.physical_address.address"] ? "error" : ""}`}
                      name='physicalAddress'
                      id='physicalAddress'
                      value={user.physical_address.address}
                      field='user.physical_address.address'
                      placeholder='Street or Box Number'
                      onChange={this.handleChange}
                      required
                    />
                    {errors["user.physical_address.address"] && (
                      <label htmlFor='physicalAddress' className='error_label'>
                        {errors["user.physical_address.address"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-items group'>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.physical_address.city"] ? "error" : ""}`}
                        name='physicalCity'
                        id='physicalCity'
                        value={user.physical_address.city}
                        field='user.physical_address.city'
                        onChange={this.handleChange}
                        placeholder='City'
                        required
                      />
                      {errors["user.physical_address.city"] && (
                        <label htmlFor='physicalCity' className='error_label'>
                          {errors["user.physical_address.city"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.physical_address.state"] ? "error" : ""}`}
                        name='physicalState'
                        id='physicalState'
                        value={user.physical_address.state}
                        field='user.physical_address.state'
                        onChange={this.handleChange}
                        placeholder='State'
                        required
                      />
                      {errors["user.physical_address.state"] && (
                        <label htmlFor='physicalState' className='error_label'>
                          {errors["user.physical_address.state"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.physical_address.zip"] ? "error" : ""}`}
                        name='physicalZip'
                        id='physicalZip'
                        value={user.physical_address.zip}
                        field='user.physical_address.zip'
                        onChange={this.handleChange}
                        placeholder='Zip'
                        required
                      />
                      {errors["user.physical_address.zip"] && (
                        <label htmlFor='physicalZip' className='error_label'>
                          {errors["user.physical_address.zip"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Mailing Address<span className='required-star'>&#x2A;</span>
                  </div>


                  
                  <div className='check_radio_group check_disclaimer' style={{ justifyContent: "normal" }}>
                    <div className='check_radio_item'>
                      <input
                        style={{ cursor: "pointer" }}
                        type='checkbox'
                        name='chkSameAddress'
                        id='chkSameAddress'
                        field='chkSameAddress'
                        checked={this.state.chkSameAddress}
                        onChange={this.handleAddress}
                      />
                      <span className='checkbox_checkbox'></span>

                      <label style={{ cursor: "pointer", marginBottom: "15px" }} htmlFor='chkSameAddress'>
                        Same as Physical Address
                      </label>
                    </div>
                  </div>
                        
                  <div className='inputs-items'>
                    <input
                      type='text'
                      className={`${errors["user.mailing_address.address"] ? "error" : ""}`}
                      name='address'
                      id='mailingAddress'
                      value={user.mailing_address.address}
                      field='user.mailing_address.address'
                      placeholder='Street or Box Number'
                      required
                      readOnly={this.state.maddress}
                      style={{ pointerEvents: this.state.maddress ? 'none' : 'auto'  }}
                    />
                    {errors["user.mailing_address.address"] && (
                      <label htmlFor='address' className='error_label'>
                        {errors["user.mailing_address.address"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className='col mailing-address'>
                <div className='inputs-col'>
                  <div className='inputs-items group'>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.mailing_address.city"] ? "error" : ""}`}
                        name='city'
                        id='city'
                        value={user.mailing_address.city}
                        field='user.mailing_address.city'
                        placeholder='City'
                        required
                        readOnly={this.state.mcity}
                        style={{ pointerEvents: this.state.mcity ? 'none' : 'auto'  }}
                      />
                      {errors["user.mailing_address.city"] && (
                        <label htmlFor='city' className='error_label'>
                          {errors["user.mailing_address.city"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.mailing_address.state"] ? "error" : ""}`}
                        name='state'
                        id='state'
                        value={user.mailing_address.state}
                        field='user.mailing_address.state'
                        placeholder='State'
                        required
                        readOnly={this.state.mstate}
                        style={{ pointerEvents: this.state.mstate ? 'none' : 'auto'  }}
                      />
                      {errors["user.mailing_address.state"] && (
                        <label htmlFor='state' className='error_label'>
                          {errors["user.mailing_address.state"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.mailing_address.zip"] ? "error" : ""}`}
                        name='zip'
                        id='zip'
                        value={user.mailing_address.zip}
                        field='user.mailing_address.zip'
                        placeholder='Zip'
                        required
                        readOnly={this.state.mzip}
                        style={{ pointerEvents: this.state.mzip ? 'none' : 'auto'  }}
                      />
                      {errors["user.mailing_address.zip"] && (
                        <label htmlFor='zip' className='error_label'>
                          {errors["user.mailing_address.zip"]}
                        </label>
                      )}
                    </div>
                  </div>
                  <p className='notes'>*Paychecks, W-2, memos, etc will be mailed here</p>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Contact Numbers<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items group two-item'>
                    <div className='grouped'>
                      <input
                        type='tel'
                        // className={`${errors["form.home_phone"] ? "error" : ""}`}
                        name='home_phone'
                        id='home_phone'
                        value={form.home_phone}
                        field='form.home_phone'
                        placeholder='Cell Phone'
                        onChange={this.handlePhoneChange}
                        // required
                      />
                      {errors["form.home_phone"] && (
                        <label htmlFor='home_phone' className='error_label'>
                          {errors["form.home_phone"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='tel'
                        className={`${errors["form.cell_phone"] ? "error" : ""}`}
                        name='cell_phone'
                        id='cell_phone'
                        value={form.cell_phone}
                        field='form.cell_phone'
                        placeholder='Secondary Phone Number'
                        onChange={this.handlePhoneChange}
                        required
                      />
                      {errors["form.cell_phone"] && (
                        <label htmlFor='cell_phone' className='error_label'>
                          {errors["form.cell_phone"]}
                        </label>
                      )}
                    </div>
                    {/* <div className='grouped'>
                      <input
                        type='tel'
                        // className={`${errors["form.message_number"] ? "error" : ""}`}
                        name='message_number'
                        id='message_number'
                        value={form.message_number}
                        field='form.message_number'
                        placeholder='Message Number'
                        onChange={this.handlePhoneChange}
                        // required
                      />
                      {errors["form.message_number"] && (
                        <label htmlFor='message_number' className='error_label'>
                          {errors["form.message_number"]}
                        </label>
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Email Address<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items group two-item'>
                    <div className='grouped'>
                      <input
                        type='email'
                        className={`${errors["user.email"] ? "error" : ""}`}
                        name='email'
                        id='email'
                        value={user.email}
                        field='user.email'
                        placeholder='Enter here'
                        required
                      />
                      {errors["user.email"] && (
                        <label htmlFor='email' className='error_label'>
                          {errors["user.email"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Emergency Contact #1
                    <span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items group two-item'>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.emgcontact1.fname"] ? "error" : ""}`}
                        name='fname'
                        id='emgContact1FName'
                        value={user.emgcontact1.fname}
                        field='user.emgcontact1.fname'
                        placeholder='Name'
                        required
                      />
                      {errors["user.emgcontact1.fname"] && (
                        <label htmlFor='fname' className='error_label'>
                          {errors["user.emgcontact1.fname"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.emgcontact1.relation"] ? "error" : ""}`}
                        name='relation'
                        id='emgcontact1Relation'
                        value={user.emgcontact1.relation}
                        field='user.emgcontact1.relation'
                        placeholder='Relationship'
                        required
                      />
                      {errors["user.emgcontact1.relation"] && (
                        <label htmlFor='relation' className='error_label'>
                          {errors["user.emgcontact1.relation"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='tel'
                        className={`${errors["user.emgcontact1.phone"] ? "error" : ""}`}
                        name='phone'
                        id='emgcontact1Phone'
                        value={user.emgcontact1.phone}
                        field='user.emgcontact1.phone'
                        placeholder='Cell Phone'
                        onChange={this.handlePhoneChange}
                        required
                      />
                      {errors["user.emgcontact1.phone"] && (
                        <label htmlFor='phone' className='error_label'>
                          {errors["user.emgcontact1.phone"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='tel'
                        className={`${errors["user.emgcontact1.alt_phone"] ? "error" : ""}`}
                        name='alt_phone'
                        id='emgcontact1AltPhone'
                        value={user.emgcontact1.alt_phone}
                        field='user.emgcontact1.alt_phone'
                        placeholder='Secondary Phone Number'
                        onChange={this.handlePhoneChange}
                        required
                      />
                      {errors["user.emgcontact1.alt_phone"] && (
                        <label htmlFor='alt_phone' className='error_label'>
                          {errors["user.emgcontact1.alt_phone"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Emergency Contact #2
                    <span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items group two-item'>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.emgcontact2.fname"] ? "error" : ""}`}
                        name='fname'
                        id='emgContact2FName'
                        value={user.emgcontact2.fname}
                        field='user.emgcontact2.fname'
                        placeholder='Name'
                        required
                      />
                      {errors["user.emgcontact2.fname"] && (
                        <label htmlFor='fname' className='error_label'>
                          {errors["user.emgcontact2.fname"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.emgcontact2.relation"] ? "error" : ""}`}
                        name='relation'
                        id='emgcontact2Relation'
                        value={user.emgcontact2.relation}
                        field='user.emgcontact2.relation'
                        placeholder='Relationship'
                        required
                      />
                      {errors["user.emgcontact2.relation"] && (
                        <label htmlFor='relation' className='error_label'>
                          {errors["user.emgcontact2.relation"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='tel'
                        className={`${errors["user.emgcontact2.phone"] ? "error" : ""}`}
                        name='phone'
                        id='emgcontact2Phone'
                        value={user.emgcontact2.phone}
                        field='user.emgcontact2.phone'
                        placeholder='Cell Phone'
                        onChange={this.handlePhoneChange}
                        required
                      />
                      {errors["user.emgcontact2.phone"] && (
                        <label htmlFor='phone' className='error_label'>
                          {errors["user.emgcontact2.phone"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='tel'
                        className={`${errors["user.emgcontact2.alt_phone"] ? "error" : ""}`}
                        name='alt_phone'
                        id='emgcontact2AltPhone'
                        value={user.emgcontact2.alt_phone}
                        field='user.emgcontact2.alt_phone'
                        placeholder='Secondary Phone Number'
                        onChange={this.handlePhoneChange}
                        required
                      />
                      {errors["user.emgcontact2.alt_phone"] && (
                        <label htmlFor='alt_phone' className='error_label'>
                          {errors["user.emgcontact2.alt_phone"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col medical-info'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Medical Info<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <textarea
                      type='text'
                      className={`${errors["form.allergies"] ? "error" : ""}`}
                      name='allergies'
                      id='allergies'
                      value={form.allergies}
                      field='form.allergies'
                      placeholder='If any Allergies, Illnesses, etc please mention here, if not write N/A'
                      required
                    />
                    {errors["form.allergies"] && (
                      <label htmlFor='allergies' className='error_label'>
                        {errors["form.allergies"]}
                      </label>
                    )}
                  </div>
                  <div className='inputs-items'>
                    <textarea
                      type='text'
                      className={`${errors["form.prescriptions"] ? "error" : ""}`}
                      name='prescriptions'
                      id='prescriptions'
                      value={form.prescriptions}
                      field='form.prescriptions'
                      placeholder='If any Prescription Medications please mention here, if not write N/A '
                      required
                    />
                    {errors["form.prescriptions"] && (
                      <label htmlFor='prescriptions' className='error_label'>
                        {errors["form.prescriptions"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='disclaimers'>
                  <strong className='diss'>Disclaimer: </strong>You must read and carefully
                  understand the documents that you are filling out and signing. These are legally
                  binding documents and must be accurate. By filling these forms out, you are
                  agreeing to our{" "}
                  <a href='https://firestormfire.com/terms-of-use/' target="blank">Terms of Use, Privacy Policy and Employee Agreements.</a>
                </div>
              </div>
            </div>
            <div className='row form-row last'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group check_disclaimer'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='disclaimer_check'
                        id='disclaimer_check'
                        field='form.disclaimer_check'
                        checked={form.disclaimer_check}
                        required
                      />
                      <span className='checkbox_checkbox'></span>

                      <label htmlFor='disclaimer_check'>
                        I have confirmed the above details are true and valid.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FormNav
              adminMode={adminMode}
              navigate={this.navigate.bind(this)}
              disclaimer_check={form.disclaimer_check}
              mobileMode={this.props.mobileMode}
              lastPage={this.props.lastPage}
            />
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs } = state;
  return { docs };
}

let connectedCandidateInfoPage = compose(withRouter, connect(mapStateToProps))(CandidateInfoPage);
export { connectedCandidateInfoPage as CandidateInfoPage };
