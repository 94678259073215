import { toast } from "react-toastify";
import moment from 'moment';
import { dateTimeConstants } from '_constants';

export const UserRoles = {
  ADMIN: 1,
  SUP_ADMIN:2
}

export const ResourceStatus = {
  1 : "Available",
  2 : "Not available",
  3 : "Committed"
}

export function validateEmail(email) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(email);
}

export function createAsyncReduxAction(apiService, actionTypes) {
  const { request, success, failure } = actionTypes;

  return (data) => {
    return (dispatch) => {
      dispatch({ type: request });

      return new Promise((resolve, reject) => {
        apiService(data)
          .then((response) => {
            dispatch({
              type: success,
              data: response?.data ?? [],
            });
            resolve(response ?? {});
          })
          .catch((error) => {
            dispatch({ type: failure, error });
            reject(error);
          });
      });
    };
  };
}


export function handleResponse(response) {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      // return json if it was returned in the response
      var contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        response.json().then((json) => resolve(json));
      } else {
        resolve();
      }
    } else {
      // return error message from response body
      response.text().then((text) => reject(text));
    }
  });
}

export function handleError(error) {
  return Promise.reject(error && error.message);
}

export function handleErrorResponse(response) {
  if (typeof response.message === "string") {
    // When response.message is a string
    toast.error(response.message);
  } else if (typeof response.message === "object") {
    // When response.message is an object
    const errorMessages = Object.values(response.message).flat();
    errorMessages.forEach((errorMessage) => {
      toast.error(errorMessage);
    });
  } else {
    // Unexpected response structure
    toast.error("Something went wrong! please contact support team!");
  }
}

export const checkCurrentUserIsSuperAdmin = (status) => {
  if(status === UserRoles.SUP_ADMIN) return true;
}

export const preventNegativeValueInDocs = (e) => {
  if (
    (e.key === "ArrowDown") ||
    (e.key === "ArrowUp") ||
    e.key === "-" ||
    e.key === "e"
  ) {
    e.preventDefault();
  }
};

export const formatDateTime = (dateTime) => {
  const momentDate = moment(dateTime);
  if (momentDate.isValid()) {
    return momentDate.format(dateTimeConstants.DATE_TIME_FORMAT);
  }
  return '-';
}

export const formatDate = (date) => {
  const momentDate = moment(date);
  if (momentDate.isValid()) {
    return momentDate.format(dateTimeConstants.DATE_FORMAT);
  }
  return '-';
}

export const getCurrentDatePST = () => {
  const date = new Date();
  const options = { timeZone: dateTimeConstants.PST_TIMEZONE, year: dateTimeConstants.NUMERIC_FORMAT, month: dateTimeConstants.TWO_DIGIT_FORMAT, day: dateTimeConstants.TWO_DIGIT_FORMAT };
  const formattedDate = date.toLocaleString(dateTimeConstants.LOCALE_DATE_STRING_FORMAT, options);

  return formattedDate.split(',')[0]; // Remove time part
};
